import { Navigate, useParams } from 'react-router-dom'
import { useApiFetcher } from '../../../common/hooks/use-api-fetcher'
import ViewJob from '../../../components/ViewJob'

export function ViewJobPage() {
  const { id } = useParams()
  const apiFetcher = useApiFetcher()

  if (!id) {
    throw new Error('Missing job id.')
  }

  if (id.length === 8) {
    // This is a legacy job without a "j" prefix. Update the URL.
    return <Navigate to={{ pathname: `/jobs/j${id}/` }} replace />
  }

  return apiFetcher ? <ViewJob apiFetcher={apiFetcher} jobId={id} /> : <></>
}
