import { useEffect, useRef, useState } from 'react'

export type IntervalEffectCallback = () => undefined | (() => void)

/**
 * Run an effect repeatedly in the manner of {@link setInterval}. Similar to
 * {@link useEffect}, except the effect callback is run immediately and then
 * repeatedly every subsequent {@link delayInMs} milliseconds.
 *
 * Whenever any {@link dependencies} change, the interval timer is torn down and
 * the cleanup function runs with old props and state; then {@link callback} runs
 * immediately with new props and state and the interval timer restarts.
 * If the browser tab becomes inactive (per {@link document.visibilityState}), the
 * timer and effect are torn down and not restarted until the tab becomes active again.
 *
 * Adapted from {@link https://overreacted.io/making-setinterval-declarative-with-react-hooks/}
 *
 * @param callback The callback to run immediately upon the first render, and
 * then repeatedly. As in {@link useEffect}, if {@link callback} returns a
 * cleanup function, it will be saved for use after a dependency change.
 * @param dependencies The dependencies for the {@link useEffect} hook.
 * @param delayInMs How much time in milliseconds to wait before invoking {@link effect}.
 * If undefined, the interval timer is not set and the behavior is very similar
 * to a plain {@link useEffect}. The callback will be run at least once, however.
 */
export function useIntervalEffect(
  callback: IntervalEffectCallback,
  dependencies: readonly unknown[],
  delayInMs: number | undefined,
) {
  const savedCallback = useRef<IntervalEffectCallback>()
  const tearDownCallback = useRef<() => void>()
  const [isDocumentVisible, setIsDocumentVisible] = useState(document.visibilityState === 'visible')

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback //  eslint-disable-line functional/immutable-data
  }, [callback])

  // watch for document visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsDocumentVisible(document.visibilityState === 'visible')
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // install / tear down the interval handler
  useEffect(
    () => {
      const tick = () => {
        tearDownCallback.current = savedCallback.current?.() // eslint-disable-line functional/immutable-data
      }

      if (isDocumentVisible) {
        tick()
      }

      if (delayInMs !== undefined && isDocumentVisible) {
        const id = setInterval(tick, delayInMs)
        return () => {
          clearInterval(id)
          tearDownCallback.current?.()
        }
      } else {
        return tearDownCallback.current
      }
    },
    // It's safe to ignore this elint error because we've added
    // `useIntervalEffect` to the `additionalHooks` option in the
    // `react-hooks/exhaustive-deps` rule (see the `.eslintrc.cjs` settings
    // file). This has the effect of checking the dependencies of the passed-in
    // effect callback, which is exactly what we want.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delayInMs, isDocumentVisible, ...dependencies],
  )
}
