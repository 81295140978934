import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'

function createMemberList(userInfo, filteredOwner) {
  if (userInfo?.user === undefined) {
    return { menuItems: [], defaultValue: '' }
  }

  let memberList = [{ label: `${userInfo.user.getEmail()} (me)`, kind: 'user', owner: userInfo.user.getEmail() }]

  const organizationOwner = {
    kind: 'organization',
    owner: userInfo.user.getOrgId(),
  }

  if (userInfo.organization !== undefined) {
    // Add the organization
    memberList = memberList.concat({
      label: 'My organization',
      ...organizationOwner,
    })
    // Add all other members of the organization
    memberList = memberList.concat(
      userInfo.organization
        .getMembers()
        .getUsersList()
        // Remove the currently logged-in user
        .filter((user) => user.getEmail() != userInfo.user.getEmail())
        // Represent the remaining members as users so that they can be
        // differentiated from the organization itself.
        .map((user) => ({
          label: user.getEmail(),
          kind: 'user',
          owner: user.getEmail(),
        })),
    )
  }

  let defaultValue
  if (filteredOwner) {
    let matchingMembers = memberList.filter(
      (member) => member.kind === filteredOwner.kind && member.owner == filteredOwner.owner,
    )
    if (matchingMembers.length > 0) {
      defaultValue = matchingMembers[0]
    } else {
      defaultValue = { label: 'None' }
    }
  }

  const menuItems = memberList.map((member) => (
    <MenuItem key={member.owner} value={member}>
      {member.label}
    </MenuItem>
  ))

  return { menuItems, defaultValue }
}

/**
 * Renders a dropdown menu with a list of creators for filtering jobs and models.

 * This is declared as a class component instead of a functional component because
 * re-defining the list of MenuItems causes the dropdown to be re-rendered each time
 * this component is rendered, which happens once every 5 seconds due to
 * our call to setInterval inside ListJobs. If the dropdown is open when this render
 * happens, it gets collapsed because the component thinks that the list of options
 * (aka MenuItems) has changed.
  */
export default class CreatorFilter extends React.Component {
  constructor(props) {
    super(props)

    const { menuItems, defaultValue } = createMemberList(props.userInfo, props.filteredOwner)
    this.menuItems = menuItems
    this.defaultValue = defaultValue

    this.StyledSelect = styled(Select)({
      '& .MuiAutocomplete-inputRoot': {
        fontSize: '0.9em',
      },
      '& ..MuiAutocomplete-option': {
        fontSize: '0.9em',
      },
    })
  }

  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          component="span"
          sx={{
            padding: '10',
            display: 'inline-block',
            margin: '0 .8em 0 0',
          }}
        >
          Creator:
        </Box>
        <FormControl>
          <this.StyledSelect
            sx={{ width: 300 }}
            size="small"
            onChange={this.props.onChange}
            defaultValue={this.defaultValue}
          >
            {this.menuItems}
          </this.StyledSelect>
        </FormControl>
      </Box>
    )
  }
}

CreatorFilter.propTypes = {
  onChange: PropTypes.func,
  userInfo: PropTypes.object,
  filteredOwner: PropTypes.object,
}
