import { SharedAccess, SharedAccessChange, User, UserList } from '../../generated/api/public_api'
import type { Logger } from '../logger'
import { createConsoleLogger } from '../logger'
import type { ApiFetcher, PagedContentOptions } from './api-fetcher'
import type { ApiFetcherCreateOptions, FetchFunction } from './api-fetcher-create-options'

type SearchParameter = [key: string, value: string]
type FetchHeaders = Readonly<Record<string, string>>

/**
 * Implements the {@link ApiFetcher} type by adding authorization headers, error
 * handling, and Protobuf deserialization. This should be treated as internal
 * and not used directly. Create an instance through {@link createApiFetcher}.
 */
export class _ApiFetcherImpl implements ApiFetcher {
  public readonly apiToken: string
  public readonly baseUrl: URL
  public readonly fetchFunction: FetchFunction
  public readonly logger: Logger

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constructor

  public constructor({
    apiToken,
    baseUrl,
    fetchFunction,
    logger = createConsoleLogger({ contextName: 'ApiFetcherImpl' }),
  }: ApiFetcherCreateOptions) {
    this.apiToken = apiToken
    this.baseUrl = new URL(baseUrl)
    this.fetchFunction = fetchFunction ?? /* istanbul ignore next */ fetch.bind(window)
    this.logger = logger
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Public Methods

  public async getAuthUser(signal: Readonly<AbortSignal>): Promise<User> {
    const user = await this.executeGet({
      relativePath: 'users/auth/user',
      signal,
      protobufDecodeFunction: (buffer) => User.decode(buffer),
    })
    return user
  }

  public async getJobSharedAccess(jobId: string, signal: Readonly<AbortSignal>): Promise<SharedAccess> {
    const sharedAccess = await this.executeGet({
      relativePath: `jobs/${jobId}/shared_access`,
      signal,
      protobufDecodeFunction: (buffer) => SharedAccess.decode(buffer),
    })
    return sharedAccess
  }

  public async getUsers(pagedContentOptions: PagedContentOptions, signal: Readonly<AbortSignal>): Promise<UserList> {
    const { limit, offset } = pagedContentOptions
    const userList = await this.executeGet({
      relativePath: 'users',
      searchParameters: [
        ['limit', limit.toString()],
        ['offset', offset.toString()],
      ],
      signal,
      protobufDecodeFunction: (buffer) => UserList.decode(buffer),
    })

    return userList
  }

  public async patchJobSharedAccess(
    jobId: string,
    addEmails: readonly string[],
    removeEmails: readonly string[],
    signal: Readonly<AbortSignal>,
  ): Promise<void> {
    await this.executePatch({
      relativePath: `jobs/${jobId}/shared_access`,
      signal,
      protobufPayloadEncodeFunction: () =>
        SharedAccessChange.encode(
          SharedAccessChange.create({ addEmail: [...addEmails], removeEmail: [...removeEmails] }),
        ).finish(),
    })
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Private Methods

  private makeUrl(relativePathParts: readonly string[], searchParameters: readonly SearchParameter[]): URL {
    // The URL _must_ end in a slash - Django requires it.
    const url = new URL(`api/v1/${relativePathParts.join('/')}/`, this.baseUrl)
    for (const [key, value] of searchParameters) {
      url.searchParams.set(key, value)
    }

    return url
  }

  /**
   * Makes request headers that contain the Protobuf content type and the
   * authorization token.
   */
  private makeRequestHeaders(): FetchHeaders {
    const tokenHeaderValue = `Token ${this.apiToken}`

    const commonHeaders = {
      'Content-Type': 'application/x-protobuf',
    }

    // To fix a Safari issue, we use X-Auth-Token in production and then let
    // NGINX forward it to Authorization. Since NGINX is not running in dev, we
    // need to use the original Authorization token directly there.
    return process.env.NODE_ENV === 'production'
      ? { 'X-Auth-Token': tokenHeaderValue, ...commonHeaders }
      : { Authorization: tokenHeaderValue, ...commonHeaders }
  }

  /**
   * Executes a GET request to the API endpoint, handling any errors and
   * deserializing the protobuf success result.
   *
   * @param relativePath - The relative path to the GET API endpoint.
   * @param searchParameters - Any search parameters to add to the request.
   * @param signal - The {@link AbortSignal} to use if the request should be
   * terminated prematurely.
   * @param protobufDecodeFunction - A function that takes a {@link Uint8Array}
   * and decodes it using the generated protobuf deserialization functions.
   *
   * @returns The deserialized protobuf API object.
   */
  private async executeGet<T>({
    relativePath,
    searchParameters = [],
    signal,
    protobufDecodeFunction,
  }: {
    readonly relativePath: string
    readonly searchParameters?: readonly SearchParameter[]
    readonly signal: Readonly<AbortSignal>
    readonly protobufDecodeFunction: (buffer: Uint8Array) => T
  }): Promise<T> {
    const url = this.makeUrl(relativePath.split('/'), searchParameters)
    const request = new Request(url, { method: 'GET', headers: this.makeRequestHeaders(), signal })

    const response = await this.executeFetch(request)
    const contentType = response.headers.get('Content-Type')

    if (contentType !== 'application/x-protobuf') {
      const errorMessage = `Response was successful, but the Content-Type was '${contentType ?? /* istanbul ignore next */ 'null'}', not 'application/x-protobuf'.`
      this.logger.error(errorMessage)
      throw new Error(errorMessage)
    }

    const buffer = await response.arrayBuffer()
    const protobuf = new Uint8Array(buffer)
    const decodedResults = protobufDecodeFunction(protobuf)
    return decodedResults
  }

  /**
   * Executes a PATCH request to the API endpoint, handling any errors, and
   * serializing the input into a protobuf buffer.
   *
   * @param relativePath - The relative path to the GET API endpoint.
   * @param signal - The {@link AbortSignal} to use if the request should be
   * terminated prematurely.
   * @param protobufPayloadEncodeFunction - A function that returns the encoded
   * protobuf input as a {@link Uint8Array}.
   */
  private async executePatch({
    relativePath,
    signal,
    protobufPayloadEncodeFunction,
  }: {
    readonly relativePath: string
    readonly signal: Readonly<AbortSignal>
    readonly protobufPayloadEncodeFunction: () => Uint8Array
  }): Promise<void> {
    const url = this.makeUrl(relativePath.split('/'), [])
    const body = protobufPayloadEncodeFunction()
    const request = new Request(url, { method: 'PATCH', body, headers: this.makeRequestHeaders(), signal })

    await this.executeFetch(request)
  }

  private async executeFetch(request: Request): Promise<Response> {
    try {
      const response = await this.fetchFunction(request)

      if (!response.ok) {
        throw new Error(`Response status: ${response.status.toString()}. Request URL: ${request.url}`)
      }

      return response
    } catch (error_: unknown) {
      const error = error_ as Error

      // If this is a signal abort error it's usually expected because a
      // component is being torn down, so just log an info instead of an error.
      if (error instanceof DOMException && error.name === 'AbortError') {
        this.logger.log(`Request aborted: ${request.url}`)
      } else {
        const message = `${error.message}${error.stack ? `\n${error.stack}` : /* istanbul ignore next */ ''}`
        this.logger.error(message)
      }

      throw error
    }
  }
}
