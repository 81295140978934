import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

// For a short wait, a spinner flashing on and off would just be distracting
const delayBeforeSpinner = 200

export function FullPageWait() {
  const [shortWait, setShortWait] = useState(true)
  useEffect(() => {
    const timeoutId = setTimeout(() => setShortWait(false), delayBeforeSpinner)
    return () => clearTimeout(timeoutId)
  }, [])

  if (shortWait) {
    return (
      <div className="main">
        <Container component="main" maxWidth="sm" />
      </div>
    )
  }

  return (
    <div className="main">
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 8,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            gap: 8,
          }}
        >
          <CircularProgress size={172} thickness={1} />
          <Typography component="h1" variant="h4">
            Logging in...
          </Typography>
        </Box>
      </Container>
    </div>
  )
}
