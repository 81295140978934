import { useCallback, useEffect, useState } from 'react'

export type UseAuthenticationTokenResult = {
  readonly token: string | undefined
  readonly isAuthenticated: boolean
  readonly setAuthenticationToken: (token: string) => void
  readonly clearAuthenticationToken: () => void
}

/**
 * The key for the token value within local storage. Don't use directly -
 * exposed for unit tests.
 */
export const TOKEN_KEY = 'token'

export function useAuthenticationToken(storage: Storage = localStorage): UseAuthenticationTokenResult {
  const [token, setToken] = useState(storage.getItem(TOKEN_KEY) ?? undefined)
  const isAuthenticated = token !== undefined

  // Reinitialize from the storage when it changes.
  useEffect(() => {
    setToken(storage.getItem(TOKEN_KEY) ?? undefined)
  }, [storage])

  const setAuthenticationToken = useCallback(
    (value: string) => {
      setToken(value)
      storage.setItem(TOKEN_KEY, value)
    },
    [storage],
  )

  const clearAuthenticationToken = useCallback(() => {
    setToken(undefined)
    storage.removeItem(TOKEN_KEY)
  }, [storage])

  return { token, isAuthenticated, setAuthenticationToken, clearAuthenticationToken }
}
