export type SharedAccessEmailItemStatus = 'adding' | 'removing' | 'hasError' | 'done'

export type SharedAccessEmailItem = {
  readonly email: string
  readonly status: SharedAccessEmailItemStatus
}

/**
 * Helper class for managing the list of emails that have access to a particular
 * job. Note that this does NOT handle communicating to the server. It just
 * handles the state of the list.
 *
 * The class is immutable. Any state changes will return a new list.
 */
export class SharedAccessEmailList {
  /**
   * Used for fast lookups of the emails in the list.
   */
  private readonly _emails: ReadonlySet<string>
  private readonly _items: readonly SharedAccessEmailItem[]

  /**
   * Gets the items, sorted by email address.
   */
  public get items() {
    return this._items
  }

  /**
   * Constructs a new instance of the {@link SharedAccessEmailList} class with
   * the specified email items.
   *
   * @param items The items to use for populating the list.
   */
  public constructor(items: readonly SharedAccessEmailItem[]) {
    this._emails = new Set(items.map(({ email }) => email.toLowerCase()))

    if (this._emails.size !== items.length) {
      throw new Error(`Duplicate email addresses detected.`)
    }

    this._items = [...items].sort(({ email: a }, { email: b }) => (a <= b ? -1 : 1))
  }

  /**
   * Creates a new instance of a {@link SharedAccessEmailList} from the supplied
   * email addresses. All of them will have the specified status.
   *
   * @param emails The email addresses, all of which will have the supplied status.
   * @param status The status to assign to each email.
   */
  public static fromEmails(emails: readonly string[], status: SharedAccessEmailItemStatus = 'done') {
    return new SharedAccessEmailList(emails.map((email) => ({ email, status })))
  }

  /**
   * Returns a value indicating whether the email is in the list (case-insensitive).
   */
  public hasEmail(email: string): boolean {
    return this._emails.has(email.toLowerCase())
  }

  /**
   * Returns the status of the specified email.
   */
  public getStatus(email: string): SharedAccessEmailItemStatus {
    const foundItem = this._items.find(({ email: itemEmail }) => itemEmail.toLowerCase() === email.toLowerCase())

    if (foundItem === undefined) {
      throw new Error(`Email '${email}' is not in the list.`)
    }

    return foundItem.status
  }

  /**
   * Sets the status for the specified email and returns a new instance of a
   * {@link SharedAccessEmailList}.
   *
   * @param email The email to set status for.
   * @param status The status of the email.
   * @returns A new instance of the list with the state changed on the item.
   */
  public setStatus(email: string, status: SharedAccessEmailItemStatus): SharedAccessEmailList {
    const lowerEmail = email.toLowerCase()
    const allButThisEmail = this._items.filter(({ email: itemEmail }) => itemEmail.toLowerCase() !== lowerEmail)

    if (allButThisEmail.length !== this._items.length - 1) {
      throw new Error(`Email '${email}' is not in the list.`)
    }

    const newList = [...allButThisEmail, { email, status }]
    return new SharedAccessEmailList(newList)
  }

  /**
   * Adds a new email address with the specified status
   * @param email The email to add
   * @param status The status of the item to add.
   * @returns A new instance of the list with the item added.
   */
  public addEmail(email: string, status: SharedAccessEmailItemStatus = 'adding'): SharedAccessEmailList {
    return new SharedAccessEmailList([...this._items, { email, status }])
  }

  /**
   * Removes the email from the list.
   * @param email The email to remove.
   * @returns A new instance of the list with the item removed.
   */
  public removeEmail(email: string): SharedAccessEmailList {
    const lowerEmail = email.toLowerCase()
    const newList = this._items.filter(({ email: itemEmail }) => itemEmail.toLowerCase() !== lowerEmail)

    if (newList.length !== this._items.length - 1) {
      throw new Error(`Email '${email}' is not in the list.`)
    }

    return new SharedAccessEmailList(newList)
  }
}
