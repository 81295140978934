import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { displayInMegabytes, displayInMillis, displayRangePbInMegabytes, pluralize } from '../common/utils'
import DeprecatedMetricsWarning from './DeprecatedMetricsWarning'
import InfoButton from './InfoButton'
import { Metric, NOT_AVAILABLE } from './Metric'
import MissingMetricsWarning from './MissingMetricsWarning'

export default function JobInferenceMetrics(props) {
  let inferenceTime
  let inferencePeakMemory
  let showMissingMetricsWarning = false
  let showDeprecatedMetricsWarning = false
  let memoryMetricClass = ''
  let memoryMetricDescription = 'The maximum amount of memory consumed to load and perform inference.'

  if (props.profileInfo.getMajorVersion() === 1) {
    if (props.profileInfo.hasExecutionMemory()) {
      // Added in 1.1
      memoryMetricDescription = 'The maximum amount of memory consumed to perform inference.'
      const executionMemory = props.profileInfo.getExecutionMemory()
      inferencePeakMemory = displayRangePbInMegabytes(executionMemory.getPeak())
    } else {
      inferencePeakMemory = displayInMegabytes(props.profileInfo.getAfterExecutionPeakMemory())
      showDeprecatedMetricsWarning = true
      memoryMetricClass = 'deprecated-metric'
    }
    inferenceTime = displayInMillis(props.profileInfo.getExecutionTime())
  } else {
    inferenceTime = displayInMillis(props.profileInfo.getExecutionTime())
    inferencePeakMemory = NOT_AVAILABLE
    showMissingMetricsWarning = true
  }
  return (
    <Accordion defaultExpanded data-testid="viewjob-result-ok">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="profilePanel-content" id="profilePanel-header">
        <Typography>
          Inference Metrics
          {showMissingMetricsWarning && <MissingMetricsWarning />}
          {showDeprecatedMetricsWarning && <DeprecatedMetricsWarning />}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ overflow: 'hidden' }} id="profilePanel-header">
        <Grid container spacing={2}>
          <Grid item align="center" xs={12} sm={6} md={4}>
            <Typography variant="body2" color="black">
              Estimated Inference Time
              <InfoButton title="An estimate is calculated after performing a number of inference repetitions." />
            </Typography>
            <Typography variant="h4" gutterBottom>
              <Metric value={inferenceTime} />
            </Typography>
          </Grid>
          <Grid item align="center" xs={12} sm={6} md={4}>
            <Typography variant="body2" color="black">
              Estimated Peak Memory Usage
              <InfoButton title={memoryMetricDescription} />
            </Typography>
            <Typography variant="h4" gutterBottom>
              <Metric value={inferencePeakMemory} className={memoryMetricClass} />
            </Typography>
          </Grid>
          {props.isProfileJob && (
            <Grid item align="center" xs={12} sm={12} md={4}>
              {props.layerPlacementInfo && (
                <>
                  <Typography variant="body2" color="black">
                    Compute Units
                    <InfoButton
                      title={
                        `A breakdown of how many of the ${props.numLayers} ` +
                        pluralize(props.numLayers, 'layer', 'layers') +
                        ' run on each compute unit.'
                      }
                    />
                  </Typography>
                  {props.computeUnitDOM}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

JobInferenceMetrics.propTypes = {
  profileInfo: PropTypes.object.isRequired,
  computeUnitDOM: PropTypes.element.isRequired,
  numLayers: PropTypes.number.isRequired,
  layerPlacementInfo: PropTypes.object.isRequired,
  isProfileJob: PropTypes.bool.isRequired,
}
