import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

export default function JobRuntimeConfig(props) {
  const profileDetail = props.profileDetail

  if (profileDetail && profileDetail.getRuntimeConfigList().length > 0) {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Runtime Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ overflow: 'hidden' }}>
          {profileDetail.getRuntimeConfigList().map((config) => (
            <Table className="table" style={{ tableLayout: 'fixed' }} key={config.getName()}>
              <TableHead>
                <TableRow key="header">
                  <TableCell>{config.getName()} Options</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {config.getPropertiesList().map((property) => (
                  <TableRow key={property.getKey()}>
                    <TableCell>
                      <pre>{property.getKey()}</pre>
                    </TableCell>
                    <TableCell>
                      <pre>{property.getValue()}</pre>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ))}
        </AccordionDetails>
      </Accordion>
    )
  }
}

JobRuntimeConfig.displayName = 'JobRuntimeConfig'
JobRuntimeConfig.propTypes = {
  profileDetail: PropTypes.object,
}
