import range from 'lodash.range'
import PropTypes from 'prop-types'

function Separator(props) {
  return (
    <div style={{ position: 'absolute', height: '100%', transform: `rotate(${props.turns}turn)` }}>
      <div style={props.style} />
    </div>
  )
}

Separator.propTypes = {
  turns: PropTypes.number.isRequired,
  style: PropTypes.any.isRequired,
}

function RadialSeparators(props) {
  const turns = 1 / props.count
  return range(props.count).map((index) => <Separator key={`rad-${index}`} turns={index * turns} style={props.style} />)
}

export default RadialSeparators
