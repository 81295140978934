import { noop } from './noop'

/**
 * Used mostly for unit tests, but it's also nice to see what the implementation
 * of the logger is at runtime (you can't really tell by just looking at the
 * functions in the debugger).
 */
export type LoggerKind = 'console' | 'noop' | 'custom'

/**
 * Very simple interface for a logger that can be used on the server or the client.
 */
export type Logger = {
  readonly kind: LoggerKind

  readonly debug: (message: string) => void
  readonly log: (message: string) => void
  readonly warn: (message: string) => void
  readonly error: (message: string) => void
}

export enum LogSeverity {
  Debug = 0,
  Log = 1,
  Warning = 2,
  Error = 3,
  None = 4,
}

/**
 * Creates an instance of the {@link Logger} interface by using the
 * {@link console} as the implementation. While this can be used on both the
 * client and the server, think twice before using it on the client. We don't
 * want to litter the console output, especially in production. On the server,
 * any console calls get written to CloudWatch logs automatically.
 *
 * @returns A {@link Logger} instance that uses the console as the
 * implementation.
 */
export function createConsoleLogger(options: { readonly contextName?: string } = {}): Logger {
  const { contextName } = options

  const severity = getDefaultSeverityFromEnvironment()

  return {
    kind: 'console',

    debug(message: string) {
      if (severity <= LogSeverity.Debug) {
        console.debug(formatMessage('debug', contextName, message))
      }
    },
    log(message: string) {
      if (severity <= LogSeverity.Log) {
        console.log(formatMessage('log', contextName, message))
      }
    },
    warn(message: string) {
      if (severity <= LogSeverity.Warning) {
        console.warn(formatMessage('warning', contextName, message))
      }
    },
    error(message: string) {
      if (severity <= LogSeverity.Error) {
        console.error(formatMessage('error', contextName, message))
      }
    },
  }
}

const getDefaultSeverityFromEnvironment = () => {
  switch (process.env.REACT_APP_LOG_SEVERITY?.toLowerCase()) {
    case 'none': {
      return LogSeverity.None
    }

    case 'error': {
      return LogSeverity.Error
    }

    case 'warning': {
      return LogSeverity.Warning
    }

    case 'log': {
      return LogSeverity.Log
    }

    default:
    case 'debug': {
      return LogSeverity.Debug
    }
  }
}

/**
 * Creates a {@link Logger} that does nothing.
 */
export function createNoopLogger(): Logger {
  return {
    kind: 'noop',

    debug: noop,
    log: noop,
    warn: noop,
    error: noop,
  }
}

type LogLevel = 'debug' | 'log' | 'warning' | 'error'

function formatMessage(level: LogLevel, contextName: string | undefined, message: string): string {
  return `${level === 'log' ? '' : `${level}: `}${contextName ? contextName + ': ' : ''}${message}`
}
