import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import Analytics from './components/Analytics'
import ErrorBoundary from './components/ErrorBoundary'
import './index.css'
import './res/fonts/fonts.css'

import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'

// Or Create your Own theme:
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#E9EBEE', // Qualcomm Nickel
        },
      },
    },
  },
  palette: {
    // MUI Themes don't support CSS variables yet.
    primary: {
      main: '#3253DC', // Qualcomm Blue
    },
    secondary: {
      main: '#4A5A75', // Qualcomm Gunmetal
    },
  },
  typography: {
    fontFamily: '"Qualcomm Next", Roboto, Helvetica, Arial, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
})

const root = createRoot(document.querySelector('#root'))

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ErrorBoundary>
          <Analytics />
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
)
