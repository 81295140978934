import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'

CodeBlock.propTypes = {
  children: PropTypes.node,
}

export default function CodeBlock(props) {
  return (
    <Paper elevation={1} sx={{ p: 1 }} style={{ backgroundColor: '#eee', overflow: 'hidden', marginBottom: '1em' }}>
      <pre>{props.children}</pre>
    </Paper>
  )
}
