import type { ApiFetcher } from './api-fetcher'
import type { ApiFetcherCreateOptions } from './api-fetcher-create-options'
import { _ApiFetcherImpl } from './api-fetcher-impl'

/**
 * Creates an object that implements the {@link ApiFetcher} interface using the
 * supplied options.
 *
 * Exposed as a separate function rather than a class for two reasons:
 * 1. To accomodate unit tests being able to supply a mock implementation (which
 *    can also be done via a class).
 * 2. Classes can be problematic in JavaScript (using `typeof` across execution
 *    boundaries). Classes are fine for an implementation of an interface, just
 *    not great to expose as THE interface.
 *
 * @param options - Options that control the behavior of the fetching.
 * @returns An object impelementing the {@link ApiFetcher} interface.
 */
export function createApiFetcher(options: ApiFetcherCreateOptions): ApiFetcher {
  return new _ApiFetcherImpl(options)
}
