import InfoIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

InfoButton.propTypes = {
  title: PropTypes.string.isRequired,
}

export default function InfoButton(props) {
  return (
    <Tooltip title={props.title}>
      <IconButton sx={{ color: '#aaa' }} aria-label="info" component="span">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
