import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthenticationToken } from '../common/hooks/use-authentication-token'

/**
 * Used together with <Route> for all destinations that should require log in
 * <Route path="/jobs" element={<PrivateRoute Component={ListJobs} />} />
 */
export function PrivateRoute({
  Component,
  storage,
  handleFormSubmitForTesting,
}: {
  readonly Component: () => React.JSX.Element
  readonly storage?: Storage
  readonly handleFormSubmitForTesting?: () => null
}) {
  const { isAuthenticated } = useAuthenticationToken(storage)

  return isAuthenticated ? <Component /> : <RedirectToSsoForm handleFormSubmitForTesting={handleFormSubmitForTesting} />
}

function RedirectToSsoForm({ handleFormSubmitForTesting }: { readonly handleFormSubmitForTesting?: () => null }) {
  const loc = useLocation()
  const next: string = loc.pathname

  const formReference = useRef<HTMLFormElement>(null)

  useEffect(() => {
    formReference.current?.submit()
  }, [])

  return (
    <form
      ref={formReference}
      data-testid="sso-login"
      onSubmit={handleFormSubmitForTesting}
      style={{ display: 'none' }}
      action="/accounts/sso_login/"
      method="POST"
    >
      <input name="provider" value="qc-sso" readOnly={true} />
      <input name="process" value="login" readOnly={true} />
      <input name="callback_url" value={next} readOnly={true} />
    </form>
  )
}
