import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'

export default function Page404() {
  useEffect(() => {
    document.title = 'Not Found (404) | AI Hub'
  }, [])

  return (
    <div className="main" data-testid="page404">
      <Box sx={{ mt: 25, mb: 25 }}>
        <Typography variant="h1" align="center">
          404
        </Typography>
        <Typography variant="h5" align="center">
          Page could not be found.
        </Typography>
      </Box>
    </div>
  )
}
