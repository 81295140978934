import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { jobStateToString } from '../common/utils'
import CreatorFilter from './CreatorFilter'
import { CompileJobStateIconTooltip, JobStateIcon } from './JobStateIcon'
import TablePaginationActions from './TablePaginationActions'

export default function TableCompileJobs(props) {
  const {
    page,
    rowsPerPage,
    jobSummaryList,
    totalQueryCount,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeCreator,
    userInfo,
    filteredOwner,
  } = props

  if (jobSummaryList && Array.isArray(jobSummaryList)) {
    let elements = []
    elements = jobSummaryList
      .map((item) => item.getCompileJobSummary())
      .map((item) => (
        <TableRow
          key={item.getJobId()}
          sx={{
            '&.MuiTableRow-hover': {
              '&:hover': {
                backgroundColor: 'light',
              },
            },
          }}
          hover
        >
          <TableCell style={{ maxWidth: '25vw' }}>
            <Link
              to={`/jobs/${item.getJobId()}/`}
              style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {item.getName()}
            </Link>
          </TableCell>
          <TableCell>
            <span className="idbox">{item.getJobId()}</span>
          </TableCell>
          <TableCell align="left">
            <div className="vertical-align-elements-padded" title={CompileJobStateIconTooltip()}>
              <JobStateIcon state={item.getJobState()} type="compile" />
              <span>{jobStateToString(item.getJobState())}</span>
            </div>
          </TableCell>
          <TableCell>{item.getDeviceName()}</TableCell>
          <TableCell>{item.getUser().getEmail()}</TableCell>
          <TableCell>{item.getCreationTime().toDate().toLocaleString()}</TableCell>
        </TableRow>
      ))

    return (
      <div>
        <TableContainer component={Paper}>
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <CreatorFilter userInfo={userInfo} onChange={handleChangeCreator} filteredOwner={filteredOwner} />
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  colSpan={8}
                  count={totalQueryCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: false,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
              <TableRow key="header">
                <TableCell>Job Name</TableCell>
                <TableCell style={{ width: 80 }}>Job ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Target Device</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Submission Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{elements}</TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  } else {
    return ''
  }
}

TableCompileJobs.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  jobSummaryList: PropTypes.array,
  totalQueryCount: PropTypes.number,
  loaded: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangeCreator: PropTypes.func,
  userInfo: PropTypes.object,
  filteredOwner: PropTypes.object,
}
