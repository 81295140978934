import PropTypes from 'prop-types'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useParams, useSearchParams } from 'react-router-dom'
import { useAuthenticationToken } from './common/hooks/use-authentication-token'
import { getAuthUser, getOrganization, useMounted } from './common/restAPI'
import Footer from './components/Footer'
import Home from './components/Home'
import ListJobs from './components/ListJobs'
import ListModels from './components/ListModels'
import LogIn from './components/LogIn'
import LogInEmail from './components/LogInEmail'
import NavBar from './components/NavBar'
import Page404 from './components/Page404'
import SupportListUsers from './components/SupportListUsers'
import ViewAccount from './components/ViewAccount'
import ViewModel from './components/ViewModel'
import { PrivateRoute } from './components/private-route'
import { ViewJobPage } from './routes/jobs/[id]/page'

const NavLayout = ({ authInfo }) => (
  <div className="nav-layout">
    <NavBar isAuthenticated={authInfo.isAuthenticated} userInfo={authInfo.userInfo} logoutCallback={authInfo.logout} />
    <Outlet />
    <Footer />
  </div>
)

NavLayout.propTypes = {
  authInfo: PropTypes.object.isRequired,
}

export default function App() {
  const { isAuthenticated, clearAuthenticationToken, setAuthenticationToken } = useAuthenticationToken()

  const [userInfo, setUserInfo] = useState({
    user: undefined,
    organization: undefined,
  })

  const login = (user, organization, token) => {
    if (token) {
      setAuthenticationToken(token)
    }
    setUserInfo({ user, organization })
  }

  const logout = () => {
    const hasToken = isAuthenticated
    setUserInfo({
      user: undefined,
      organization: undefined,
    })
    clearAuthenticationToken()
    // If the user has a token, this was a logout based on failed
    // credentials. Force a refresh to prevent rendering a log-in
    // protected page (without information).
    if (hasToken) {
      window.location.reload(false)
    }
  }

  useEffect(() => {
    // Fetch logged in user data
    if (isAuthenticated) {
      let [mountState, tearDownMounted] = useMounted()

      // Get authenticated user's details
      getAuthUser(
        mountState,
        (info) => {
          // Get authenticated user's organization's details
          getOrganization(
            info.getOrgId(),
            mountState,
            (orgInfo) => {
              login(info, orgInfo)
            },
            () => {
              console.error(`Could not get details for organization: ${userInfo.user.getOrgId()}`)
              logout()
            },
          )
        },
        () => {
          logout()
        },
      )

      return tearDownMounted
    }
  }, [isAuthenticated])

  const HomeWrapper = () => {
    return <Home userInfo={userInfo} />
  }

  const ViewModelWrapper = () => {
    const params = useParams()
    return <ViewModel modelId={params.id} />
  }

  const ViewVisualizationWrapper = () => {
    const params = useParams()
    const ViewVisualizationFullscreen = lazy(() => import('./components/ViewVisualization'))
    return (
      <Suspense>
        <ViewVisualizationFullscreen jobId={params.id} />
      </Suspense>
    )
  }

  const ExperimentalViewJobComparisonWrapper = () => {
    const ExperimentalViewJobComparison = lazy(() => import('./components/ExperimentalViewJobComparison'))
    const [search] = useSearchParams()
    const jobIds = search.get('jobs').split(',')
    let groupByDevice = search.get('groupByDevice')
    if (groupByDevice != null) {
      groupByDevice = Number.parseInt(groupByDevice)
    } else {
      groupByDevice = 1
    }
    return (
      <Suspense>
        <ExperimentalViewJobComparison jobIds={jobIds} groupByDevice={groupByDevice} />
      </Suspense>
    )
  }

  // List views use one-based indexing in the URL, but zero-based internally
  const ListJobsWrapper = () => {
    const [search] = useSearchParams()
    const page = (search.get('page') ?? 1) - 1
    var rowsPerPage = search.get('rowsPerPage')
    if (rowsPerPage != null) {
      rowsPerPage = Number.parseInt(rowsPerPage)
    }
    let jobType = search.get('type') ?? 'compile'
    let filteredOwner
    if (search.get('ownerKind') && search.get('ownerName')) {
      filteredOwner = {
        kind: search.get('ownerKind'),
        owner: search.get('ownerName'),
      }
    } else if (userInfo.user) {
      filteredOwner = {
        kind: 'user',
        owner: userInfo.user.getEmail(),
      }
    }

    // Force re-load upon button press (otherwise page > 1 won't return to page == 1)
    const key = Math.random()

    return (
      <ListJobs
        page={page}
        rowsPerPage={rowsPerPage}
        jobType={jobType}
        key={key}
        userInfo={userInfo}
        filteredOwner={filteredOwner}
      />
    )
  }

  const ListModelsWrapper = () => {
    const [search] = useSearchParams()
    const page = (search.get('page') ?? 1) - 1
    // Force re-load upon button press (otherwise page > 1 won't return to page == 1)
    const key = Math.random()

    let filteredOwner
    if (search.get('ownerKind') && search.get('ownerName')) {
      filteredOwner = {
        kind: search.get('ownerKind'),
        owner: search.get('ownerName'),
      }
    } else if (userInfo.user) {
      filteredOwner = {
        kind: 'user',
        owner: userInfo.user.getEmail(),
      }
    }

    return <ListModels page={page} key={key} userInfo={userInfo} filteredOwner={filteredOwner} />
  }

  const SupportListUsersWrapper = () => {
    return <SupportListUsers loginCallback={login} logoutCallback={logout} />
  }

  const ViewAccountWrapper = () => {
    const [search] = useSearchParams()
    const tab = search.get('tab') ?? 'account'
    const showPasswordChange = search.has('changePassword')

    return <ViewAccount userInfo={userInfo} tab={tab} showPasswordChange={showPasswordChange} />
  }

  const LogInWrapper = () => {
    const [search] = useSearchParams()
    return <LogIn loginCallback={login} ssoResponseCode={search.get('code')} ssoState={search.get('state')} />
  }

  const LogInEmailWrapper = () => {
    return <LogInEmail loginCallback={login} />
  }

  const authInfo = { isAuthenticated, userInfo: userInfo.user, logout }
  const NavLayoutWrapper = () => <NavLayout authInfo={authInfo} />

  return (
    <Routes>
      <Route path="/jobs/:id/visualization" element={<PrivateRoute Component={ViewVisualizationWrapper} />} />
      <Route path="/signin" element={<NavLayoutWrapper />}>
        <Route index element={<LogInWrapper />} />
      </Route>
      <Route path="/signin-email" element={<NavLayoutWrapper />}>
        <Route index element={<LogInEmailWrapper />} />
      </Route>
      <Route path="/" element={<PrivateRoute Component={NavLayoutWrapper} />}>
        <Route exact path="/" element={<HomeWrapper />} />
        <Route path="jobs" element={<ListJobsWrapper />} />
        <Route path="jobs/:id" element={<ViewJobPage />} />
        <Route path="job_comparison" element={<ExperimentalViewJobComparisonWrapper />} />
        <Route path="models" element={<ListModelsWrapper />} />
        <Route path="models/:id" element={<ViewModelWrapper />} />
        <Route path="support" element={<SupportListUsersWrapper />} />
        <Route path="account" element={<ViewAccountWrapper />} />
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  )
}
