import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

ListItemWithIcon.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  caption: PropTypes.any,
}

export default function ListItemWithIcon(props) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'var(--qualcomm_gunmetal)', mt: 0.5 }}>{props.icon}</Avatar>
      </ListItemAvatar>
      <ListItemText disableTypography secondary={props.children}>
        <Typography variant="caption" color="gray">
          {props.caption}
        </Typography>
      </ListItemText>
    </ListItem>
  )
}
