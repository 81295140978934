import { useEffect, useState } from 'react'
import type { ApiFetcher } from '../api-fetcher'
import { createApiFetcher } from '../api-fetcher'
import { useAuthenticationToken } from './use-authentication-token'

export type UseApiFetcherOptions = {
  /**
   * Function used to create an {@link ApiFetcher}. Defaults to the real
   * implementation of the {@link ApiFetcher} interface, but supplied here for
   * easy mocking in unit tests.
   */
  readonly createApiFetcherFunction?: typeof createApiFetcher

  /**
   * Contains the current window location origin. Defaults to `window.origin`,
   * but supplied here for easy mocking in unit testing.
   */
  readonly locationOrigin?: string

  /**
   * Contains a mechanism to interact with the browser's localStorage. Defaults
   * to using localStorage, but supplied here for easy mocking in unit tests.
   */
  readonly storage?: Storage
}

export function useApiFetcher(options?: UseApiFetcherOptions): ApiFetcher | undefined {
  const { createApiFetcherFunction, locationOrigin, storage } = resolveOptions(options)

  const { token: apiToken } = useAuthenticationToken(storage)
  const [apiFetcher, setApiFetcher] = useState<ApiFetcher | undefined>(
    apiToken ? createApiFetcherFunction({ apiToken, baseUrl: locationOrigin }) : undefined,
  )

  // Create a new ApiFetcher once there is a valid token or clear an existing
  // ApiFetcher if the token is cleared.
  useEffect(() => {
    if (apiToken) {
      const apiFetcher = createApiFetcherFunction({ apiToken, baseUrl: locationOrigin })
      setApiFetcher(apiFetcher)
    } else {
      setApiFetcher(undefined)
    }
  }, [apiToken, locationOrigin, createApiFetcherFunction])

  return apiFetcher
}

// Pulled into a separate function because the Jest transpiler doesn't correctly
// handle default parameter assignments and we can't get 100% coverage
// otherwise. It's a well-known issue that `istanbul ignore next` doesn't work
// correctly on default parameters in TypeScript, so this is the recommended
// pattern. And the alternative to unit testing that the default parameters are
// assigned properly is very messy in this case and requires mocking all of the
// system functions. It just isn't worth it.

/* istanbul ignore next */
function resolveOptions(options: UseApiFetcherOptions = {}): Required<UseApiFetcherOptions> {
  const {
    createApiFetcherFunction = createApiFetcher,
    locationOrigin = window.location.origin,
    storage = localStorage,
  } = options

  return { createApiFetcherFunction, locationOrigin, storage }
}
