import ErrorIcon from '@mui/icons-material/Error'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'

ErrorButton.propTypes = {
  title: PropTypes.string.isRequired,
}

export default function ErrorButton(props) {
  return (
    <Tooltip title={props.title}>
      <IconButton sx={{ color: 'var(--orange)' }} aria-label="info" component="span">
        <ErrorIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  )
}
