import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthenticationToken } from '../common/hooks/use-authentication-token'
import { authSSOLogin, getAuthUser, getOrganization, useMounted } from '../common/restAPI'
import { FullPageWait } from './FullPageWait'

LogIn.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  ssoResponseCode: PropTypes.string,
  ssoState: PropTypes.string,
}

export default function LogIn({ loginCallback: login, ssoResponseCode, ssoState }) {
  const { clearAuthenticationToken, setAuthenticationToken } = useAuthenticationToken()

  const navigate = useNavigate()

  const loggingIn = ssoResponseCode && ssoState

  const [errors, setErrors] = useState(false)

  const loginFailed = () => {
    console.log('Failed to sign in')
    clearAuthenticationToken()
    setErrors(true)
  }

  const onUserTokenReceived = (key, next, mountState) => {
    if (!key) {
      loginFailed()
      return
    }

    setAuthenticationToken(key)

    // Get the full user info
    getAuthUser(
      mountState,
      (info) => {
        getOrganization(
          info.getOrgId(),
          mountState,
          (orgInfo) => {
            login(info, orgInfo, key)
            setErrors(false)
            navigate(next ?? '/', { replace: true })
          },
          loginFailed,
        )
      },
      loginFailed,
    )
  }

  useEffect(() => {
    document.title = 'Sign In | AI Hub'
  }, [])

  // attempt sso login with code returned from openID callback redirect
  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()

    if (ssoResponseCode && ssoState) {
      authSSOLogin(
        ssoResponseCode,
        ssoState,
        mountState,
        ({ key, next }) => onUserTokenReceived(key, next, mountState),
        () => setErrors(true),
      )
    }

    return tearDownMounted
  }, [ssoResponseCode, ssoState])

  if (loggingIn && !errors) {
    return <FullPageWait />
  }

  return (
    <div className="main">
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form action="/accounts/sso_login/" method="POST">
              <input type="hidden" name="provider" value="qc-sso" readOnly={true} />
              <input type="hidden" name="process" value="login" readOnly={true} />
              <input type="hidden" name="callback_url" value="/" readOnly={true} />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 5, mb: 5 }}>
                Sign in with Qualcomm MyAccount
              </Button>
            </form>

            {errors && <Alert severity="error">Unable to sign in. New User? Please try again in a few minutes.</Alert>}
          </Box>
        </Box>
      </Container>
    </div>
  )
}
