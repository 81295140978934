import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getUsers, useMounted } from '../common/restAPI'
import Page404 from './Page404'
import TablePaginationActions from './TablePaginationActions'

export default function SupportListUsers(props) {
  const rowsPerPage = 10
  const { logoutCallback, loginCallback } = props

  const [authorized, setAuthorized] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [userList, setUserList] = useState([])
  const [totalQueryCount, setTotalQueryCount] = useState(0)
  const [page, setPage] = useState(props.page ?? 0)

  const loginAs = (token, email) => {
    logoutCallback()
    let userInfo = { getEmail: () => email }
    loginCallback(userInfo, token)

    window.location.replace('/jobs')
  }

  const handleChangePage = (event, newPage) => {
    if (newPage != page) {
      let url = '/support/'
      if (newPage >= 1) {
        url += '?page=' + newPage
      }
      window.history.replaceState(null, '', url)
      setPage(newPage)
    }
  }

  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()

    getUsers(
      page,
      rowsPerPage,
      mountState,
      (userListPb) => {
        setUserList(userListPb.getUsersList())
        setTotalQueryCount(userListPb.getTotalQueryCount())
        setAuthorized(true)
        setLoaded(true)
      },
      () => {
        setUserList([])
        setTotalQueryCount(0)
        setAuthorized(false)
        setLoaded(true)
      },
    )

    return tearDownMounted
  }, [page])

  const renderUsers = () => {
    let elements = []
    if (userList && Array.isArray(userList)) {
      elements = userList.map((userPb) => (
        <TableRow key={userPb.getId()}>
          <TableCell>{userPb.getEmail()}</TableCell>
          <TableCell>{userPb.getFirstName()}</TableCell>
          <TableCell>{userPb.getLastName()}</TableCell>
          <TableCell align="right">
            <Button variant="outlined" onClick={() => loginAs(userPb.getToken(), userPb.getEmail(), userPb.getId())}>
              Sign in
            </Button>
          </TableCell>
        </TableRow>
      ))
    }

    return (
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow key="header">
              <TableCell>Email</TableCell>
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{elements}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                colSpan={7}
                count={totalQueryCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: false,
                }}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  if (!loaded) {
    return ''
  }

  if (!authorized) {
    return <Page404 />
  }

  return (
    <div className="main">
      <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
        Support - User Access
      </Typography>
      {renderUsers()}
    </div>
  )
}

SupportListUsers.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  logoutCallback: PropTypes.func.isRequired,
  page: PropTypes.number,
}
