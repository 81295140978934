import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authLogout, getServiceStatus, useMounted } from '../common/restAPI'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export default function NavBar(props) {
  const isAuthenticated = props.isAuthenticated
  const userInfo = props.userInfo
  const logout = props.logoutCallback

  const [openLogoutModal, setOpenLogoutModal] = React.useState(false)
  const handleLogoutModalOpen = () => setOpenLogoutModal(true)
  const handleLogoutModalClose = () => setOpenLogoutModal(false)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const userMenuOpen = Boolean(anchorEl)
  const [serviceStatusMessage, setServiceStatusMessage] = React.useState(null)
  const [sidebarVisible, setSidebarVisible] = React.useState(false)

  const navigate = useNavigate()

  const handleUserMenuClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  const refreshStatus = React.useCallback(() => {
    const [mountState, tearDownMounted] = useMounted()

    // Get authenticated user's details
    getServiceStatus(
      mountState,
      (serviceStatusPb) => {
        if (serviceStatusPb.getMessage() !== '') {
          setServiceStatusMessage(serviceStatusPb.getMessage())
        } else {
          setServiceStatusMessage(null)
        }
      },
      () => {
        setServiceStatusMessage(null)
      },
    )

    return tearDownMounted
  }, [serviceStatusMessage])

  const refreshRateSeconds = 5 * 60
  React.useEffect(() => {
    let tearDownMounted = refreshStatus()

    const interval = setInterval(() => {
      tearDownMounted = refreshStatus()
    }, refreshRateSeconds * 1000)

    return () => {
      clearInterval(interval)
      tearDownMounted()
    }
  }, [refreshStatus, refreshRateSeconds])

  const handleLogout = (e) => {
    e.preventDefault()
    let [mountState, tearDownMounted] = useMounted()

    // also close modal window if it's open
    setOpenLogoutModal(false)

    authLogout(
      mountState,
      () => {
        logout()
        navigate('/signin')
        // Also hard-redirect to clear out any lingering state
        window.location.replace('/signin')
      },
      (err) => {
        console.log(err)
        logout()
        navigate('/signin')
        // Also hard-redirect to clear out any lingering state
        window.location.replace('/signin')
      },
    )

    return tearDownMounted
  }

  const navbarLogo = (
    <div className="navbar--logo">
      <Link to="/">
        <img src="/ai-cloud.png" height="45px" alt="" aria-hidden="true" />
        <span>Qualcomm® AI Hub</span>
      </Link>
    </div>
  )

  const navItems = (
    <Box sx={{ padding: '20px 0px' }}>
      {isAuthenticated === true ? (
        <Box
          className="nav--links"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            alignItems: 'stretch',
          }}
        >
          <Button href="/jobs/" color="secondary" sx={{ justifyContent: 'start', marginRight: 0 }}>
            Jobs
          </Button>
          <Button href="/models/" color="secondary" sx={{ justifyContent: 'start', marginRight: 0 }}>
            Models
          </Button>
          <Button href="/docs/" color="secondary" sx={{ justifyContent: 'start', marginRight: 0 }}>
            Documentation
          </Button>
          {userInfo && (
            <>
              <Button
                onClick={handleUserMenuClick}
                color="secondary"
                sx={{ display: 'flex', justifyContent: 'start' }}
                startIcon={<PersonIcon />}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {userInfo.getEmail()}
              </Button>
            </>
          )}
        </Box>
      ) : (
        <>
          <Button color="secondary" component={Link} to="/signin">
            Sign in
          </Button>
        </>
      )}
    </Box>
  )

  return (
    <div>
      {serviceStatusMessage && (
        <Alert severity="warning" className="maintenance-banner">
          <div dangerouslySetInnerHTML={{ __html: serviceStatusMessage }} />
        </Alert>
      )}
      <nav className="main-nav">
        <IconButton sx={{ display: { md: 'none' } }} onClick={() => setSidebarVisible(true)}>
          <MenuIcon />
        </IconButton>
        {navbarLogo}
        <div>
          <Modal
            open={openLogoutModal}
            onClose={handleLogoutModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirmation
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                Are you sure you want to sign out?
              </Typography>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button onClick={handleLogout}>Sign out</Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleLogoutModalClose}>Cancel</Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={userMenuOpen}
            onClose={handleUserMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem component={Link} to={'/account/'} onClick={handleUserMenuClose}>
              Settings
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleLogoutModalOpen()
                handleUserMenuClose()
              }}
            >
              Sign out
            </MenuItem>
          </Menu>

          {/*
          We render navItems inside the drawer as well as outside. Visibility of the hamburger button to show the
          drawer is dependent on screen size, and the contents of the drawer aren't visible unless the drawer is
          open.
          */}
          <Drawer anchor={'left'} open={sidebarVisible} onClose={() => setSidebarVisible(false)}>
            <Box sx={{ minWidth: '300px', padding: '0 20px' }}>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                {navbarLogo}
                <Button sx={{ marginLeft: 'auto' }} onClick={() => setSidebarVisible(false)}>
                  <CloseIcon />
                </Button>
              </Box>
              <Divider />
              {navItems}
            </Box>
          </Drawer>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>{navItems}</Box>
        </div>
      </nav>
    </div>
  )
}

NavBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  userInfo: PropTypes.object,
  logoutCallback: PropTypes.func.isRequired,
}
