import PropTypes from 'prop-types'

export const NOT_AVAILABLE = 'N/A'

export function Metric(props) {
  let className = props.className !== undefined ? props.className : ''
  let value = props.value

  const AvailableMetric = () => {
    return <span className={className}>{value}</span>
  }

  const UnavailableMetric = () => {
    return (
      <span className={className} style={{ color: '#aaa' }}>
        {NOT_AVAILABLE}
      </span>
    )
  }

  return props.value === NOT_AVAILABLE ? <UnavailableMetric /> : <AvailableMetric {...props} />
}

Metric.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
}
