import QualcommLogo from '../res/qualcomm-logo.svg'
import './Analytics.css'

export default function Footer() {
  return (
    <footer className="app-footer">
      <div className="app-footer-logo">
        <a href="https://www.qualcomm.com" target="_blank" rel="noreferrer">
          <img src={QualcommLogo} alt="Qualcomm Logo" />
        </a>
      </div>

      <nav aria-label="legal links">
        <ul>
          <a href="http://www.qualcomm.com/site/terms-of-use" target="_blank" rel="noreferrer">
            Terms of Use
          </a>
          <a href="http://www.qualcomm.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <a href="http://www.qualcomm.com/cookie-policy" target="_blank" rel="noreferrer">
            Cookie Policy
          </a>
          <a href="https://www.qualcomm.com/contact/support" target="_blank" rel="noreferrer">
            Contact Us
          </a>
          {/* The analytics script will magically find this OneTrust button and attach a click handler */}
          <button className="ot-sdk-show-settings" id="ot-sdk-btn">
            Cookie Settings
          </button>
        </ul>
      </nav>

      <div className="copyright">
        <p>© 2015-{new Date().getFullYear()} Qualcomm Technologies, Inc. and/or its affiliated companies.</p>
        <p>
          Snapdragon and Qualcomm branded products are products of Qualcomm Technologies, Inc. and/or its subsidiaries.
          Qualcomm patented technologies are licensed by Qualcomm Incorporated.
        </p>
        <p>
          Note: Certain product kits, tools and materials may require you to accept additional terms and conditions
          before accessing or using those items.
        </p>
        <p>
          References to &quot;Qualcomm&quot; may mean Qualcomm Incorporated, or subsidiaries or business units within
          the Qualcomm corporate structure, as applicable.
        </p>
        <p>
          Qualcomm Incorporated includes our licensing business, QTL, and the vast majority of our patent portfolio.
          Qualcomm Technologies, Inc., a subsidiary of Qualcomm Incorporated, operates, along with its subsidiaries,
          substantially all of our engineering, research and development functions, and substantially all of our
          products and services businesses, including our QCT semiconductor business.
        </p>
        <p>
          Materials that are as of a specific date, including but not limited to press releases, presentations, blog
          posts and webcasts, may have been superseded by subsequent events or disclosures.
        </p>
        <p>Nothing in these materials is an offer to sell any of the components or devices referenced herein.</p>
      </div>
    </footer>
  )
}
